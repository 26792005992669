.spin-input {
  outline: none;
  box-shadow: none;
  border: none;
  background: white;
  font-weight: bold;
  height: 38px;
  color: $darker-gray;
  font-size: 1.125rem;
  max-width: -webkit-fill-available;
  max-width: 35px;
  text-align: center;
  margin: 0 20px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &__container {
    position: relative;
    background: white;
    border: 1px solid #979797;
    border-radius: 4px;
    min-width: 70px;
  }

  &__minus,
  &__plus {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $darker-gray;
    cursor: pointer;
    user-select: none;
    font-size: 1rem;
    padding-top: 3px;

    &:before {
      font-weight: 500;
    }

    &.is-disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  &__minus {
    left: 5px;
  }

  &__plus {
    right: 5px;
  }
}

;@import "sass-embedded-legacy-load-done:123";