.trapeze-button {
  &__wrapper {
    width: fit-content;
    height: 3em;
    padding: 0 2em 0 3em;
    margin: 0 0 0 2em;
    border: none;
    border-radius: 3em 0 0 0;
    position: relative;
    display: flex;
    align-items: center;
    color: $white;
    font-weight: bold;
    transition: border-bottom-color 150ms linear, background-color 150ms linear !important;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      filter: brightness(1.2);
      color: $white;
    }

    &::after {
      content: '';
      display: block;
      height: 0;
      width: 0;
      border: 2em solid transparent;
      position: absolute;
      bottom: 0;
      left: -1.22em;
      transition: border-bottom-color 150ms linear, background-color 150ms linear !important;
    }

    &--default {
      background-color: #3f3f3f !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: #3f3f3f !important;
      }

      &::after {
        border-bottom-color: #3f3f3f;
      }
    }

    &--primary {
      background-color: $primary !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $primary !important;
      }

      &::after {
        border-bottom-color: $primary;
      }
    }

    &--secondary {
      background-color: $secondary !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $secondary !important;
      }

      &::after {
        border-bottom-color: $secondary;
      }
    }

    &--green {
      background-color: $success !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $success !important;
      }

      &::after {
        border-bottom-color: $success;
      }
    }

    &--gray {
      color: $darker-gray;
      background-color: $v-light-gray !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $v-light-gray !important;
      }

      &:hover {
        color: $darker-gray;
      }

      &::after {
        border-bottom-color: $v-light-gray;
      }
    }

    &--red {
      background-color: $danger !important;

      &:hover,
      &:active,
      &:focus-visible {
        background-color: $danger !important;
      }

      &::after {
        border-bottom-color: $danger;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:124";