.authorization-layout {
  &__container {
    background-color: $light-azure;
    min-height: 100vh;
  }

  &__content-container {
    max-width: 420px;
    width: 420px;

    &.is-refund {
      max-width: 100%;
      width: 550px;
    }

    &.is-data-processing {
      width: 100%;
      max-width: 100%;

      li {
        margin-top: 5px;
      }
    }

    @include screen-sm-max {
      width: 100%;
      max-width: 100%;
    }

    &.is-account-creation {
      width: auto;
      max-width: 900px;

      .account-creation {
        display: flex;
        column-gap: 100px;

        @include screen-md-max {
          column-gap: 50px;
        }

        @include screen-sm-max {
          flex-direction: column;
          column-gap: 10px;
        }

        &__inputs {
          min-width: 420px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-width: 500px;

          @include screen-sm-max {
            min-width: auto;
            max-width: 760px;
          }
        }

        &__download-app-buttons {
          display: flex;
          gap: 10px;

          @include screen-sm-max {
            justify-content: center;
          }

          &__wrapper {
            margin-top: 50px;

            @include screen-sm-max {
              margin-top: 30px;
            }
          }

          .download-app-button {
            max-width: 100%;
            max-height: 32px;
            margin-top: 0 !important;

            @include screen-sm-max {
              max-height: 40px;
            }
          }
        }
      }
    }
  }

  &__button {
    height: 44px;
    color: $white;
    margin-top: 30px;
    width: 100%;
    display: block;
    font-weight: bold;

    &:hover,
    &:focus,
    &:active {
      color: $white !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:98";