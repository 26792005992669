.content-loader {
  position: relative;
  max-height: 235px;
  min-height: 235px;
  pointer-events: none;
  overflow: hidden;
  transition: max-height 2s linear;

  @include screen-xs-max {
    max-height: 170px;
    min-height: 170px;
  }

  &--full-screen {
    min-height: 100vh !important;

    .content-loader__loader {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: $white;
    width: 100%;
    height: 100%;
    min-height: 235px;
    z-index: 2;
    opacity: 1;
    pointer-events: none;

    @include screen-xs-max {
      min-height: 170px;
    }
  }

  &.is-hidden {
    pointer-events: all;
    max-height: 10000px;
    overflow: visible;
  }

  &:not(.is-hidden) {
    min-height: 235px;

    @include screen-xs-max {
      min-height: 170px;
    }
  }

  &.is-hidden:after {
    @include fadeOutAnimation;
  }

  &__loader {
    width: 105px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 65px;
    opacity: 1;
    z-index: 3;
    pointer-events: none;

    @include screen-xs-max {
      width: 90px;
    }

    &.is-hidden {
      @include fadeOutAnimation;
    }
  }

  &__content {
    opacity: 0;

    &.is-visible {
      animation-delay: 300ms;
      @include fadeInAnimation;
    }
  }
}

;@import "sass-embedded-legacy-load-done:113";