.cashback-voucher {
  &__expandable-row {
    &.is-generating {
      background-color: rgba(44, 143, 65, 0.05);
      background-image: url('@assets/images/generating-cashback-bg.svg');
      background-position: right top;
      background-size: 50% 95px;

      .progress-with-icon__progress {
        background: linear-gradient(100deg, #2c8f41 0, #a8c25f 100%);
      }

      .progress-with-icon__progress-wrapper {
        background: linear-gradient(100deg, #2c8f41 100%, #a8c25f 0);
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:147";