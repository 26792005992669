.backdrop-loader {
  &__loader {
    left: 50%;
    top: 50%;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, -50%);
    max-height: 80%;
  }

  &__backdrop {
    backdrop-filter: blur(2px);
    transition: opacity ease-in-out 0.3s;
    z-index: 1;

    &.visible {
      opacity: 1;
    }

    &.invisible {
      opacity: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:115";