.error-page {
  &__wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 10;
    overflow: auto;
    padding-bottom: 20px;
  }

  &__content {
    width: 65%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include fadeInAnimation;

    @include screen-lg-max {
      width: 95%;
    }
  }

  &__logo-section {
    white-space: nowrap;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    @include screen-lg-max {
      margin-top: 50px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90%;
      white-space: pre-wrap;
      text-align: center;
    }
  }

  &__logo {
    margin-bottom: 40px;
    height: 40px;
    width: 145px;

    @include screen-lg-max {
      margin: 0 auto 40px auto;
    }
  }

  &__text {
    font-size: 20px;
    color: #656565;

    &--bold {
      color: #434343;
    }
  }

  &__button {
    margin-top: 20px;
    background: $dark-gray-gradient;
    padding: 14px 30px;
    color: white;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      color: #e5e5e5;
    }
  }

  &__image {
    margin-top: 80px;

    @include screen-lg-max {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 300px;
      margin-top: 120px;
    }
  }

  &__number-msg {
    color: $primary;
    display: block;
    font-size: 20px;
    top: 24px;
    position: absolute;
    text-shadow: 1px 0 $primary;
    letter-spacing: 2px;
    font-weight: bold;

    &.is-404 {
      left: 43px;
    }

    &.is-500 {
      left: 15px;
    }
  }

  &__number {
    display: block;
    margin-bottom: 40px;
    font-size: 160px;
    color: #0f2453;
    letter-spacing: 8px;
    text-shadow: 5px 0 #0f2453;

    @include screen-xl-max {
      margin-bottom: 20px;
      font-size: 150px;
      width: 310px;
    }

    &__shadow {
      position: absolute;
      font-size: 160px;
      color: white;
      text-shadow: -1px 0 #0f2453, 0 1px #0f2453, 1px 0 #0f2453, 0 -1px #0f2453;
      top: 4px;
      left: 12px;
      z-index: -1;
      letter-spacing: 8px;

      @include screen-xl-max {
        font-size: 150px;
      }
    }
  }

  &__number-wrapper {
    position: relative;
    margin-top: 50px;

    @include screen-lg-max {
      margin-top: 285px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:148";