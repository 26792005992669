$navy-light: #0f2453;
$navy-dark: #091a40;
$gold-light: #b99153;
$gold-dark: #8b764d;
$green-pearl: #2c8f41;
$dark-gray: #4e565e;
$darker-gray: #576067;
$base-gray: #606a72;
$light-gray: #767d83;
$lighter-gray: #9099a1;
$v-light-gray: #cdcfd2;
$light-blue: #eff3f7;
$secondary-light-blue: #f7fbfe;
$light-azure: #ecf5fc;
$black-olive: #3f3e3e;
$dark-gray-gradient: linear-gradient($base-gray, #444c52);
$gold-gradient: linear-gradient(#c2a366, #947648);
$turquoise: #0acf97;
$danger-transparent: #dc35451a;
$smoke-white: #f8f8f8;
$bright-red: #da001a;
$light-black: #3f3f3f;
$alice-blue: #f0f7fb;
$tropical-blue: #d3e8f8;

// bootstrap palette
$enable-negative-margins: true;
$accordion-icon-color: $darker-gray;
$accordion-icon-active-color: $darker-gray;
$primary: $gold-light;
$secondary: $navy-light;
$success: $green-pearl;
$danger: $bright-red;

;@import "sass-embedded-legacy-load-done:3";