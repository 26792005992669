.labeled-row {
  &__wrapper {
    display: flex;
    gap: 1rem;
    @include screen-lg-max {
      flex-direction: column;
    }
  }

  &__label {
    flex: 4;

    &.hide-on-wrap {
      @include screen-lg-max {
        display: none;
      }
    }
  }

  &__content {
    flex: 8;

    &.hide-label {
      label.form-label {
        @include screen-xl-min {
          display: none !important;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:104";