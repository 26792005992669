.progress-with-icon {
  &__wrapper {
    flex: 1;
  }

  &__progress-wrapper {
    overflow: hidden;
    height: 0.4rem;
    background: $v-light-gray;
    border-radius: 0.2rem;
  }

  &__progress {
    height: 100%;
    background: currentColor;
    border-radius: 0.2rem;
  }
}

;@import "sass-embedded-legacy-load-done:107";