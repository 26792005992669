.circle-indicator {
  &__wrapper {
    border: solid 1px $v-light-gray;
    border-radius: 50%;
  }

  &__indicator {
    top: 0;
    right: 0;
    height: 0.75em;
    width: 0.75em;
    border-radius: 50%;
    color: white;

    &-icon {
      font-size: 0.6em;
    }

    &--gray {
      background-color: $dark-gray;
    }

    &--green {
      background-color: $green-pearl;
    }
  }

  &__content {
    height: 2em;
    width: 2em;
    opacity: 0.5;

    &--checked {
      opacity: 1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:108";