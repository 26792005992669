.completion-tabs {
  &__tab {
    border: none;
    opacity: 0.4;
    border-bottom: 1px solid #979797;
    height: 42px;
    background-color: $white;

    &:focus {
      outline: 0;
    }

    &--active {
      opacity: 1;
      border-bottom: 2px solid $primary;
    }

    &--complete {
      color: $success;
    }
  }
}

;@import "sass-embedded-legacy-load-done:110";