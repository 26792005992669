.circle-outline-radio-button {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: initial;
  color: $text-muted;
  font-size: 16px;
  font-weight: bold;

  &--tall {
    height: 60px;

    .form-check-input {
      margin: 0 20px 0 0;
    }
  }

  @include screen-xl-min {
    height: 45px;
  }

  &__spinner {
    position: absolute;
    top: 36%;
    height: 12px;
    width: 12px;
    font-size: 2px;
    right: -30px;
    opacity: 0;
    transition: opacity 100ms linear, right 100ms linear;

    &.is-loading {
      right: -20px;
      opacity: 1;
    }
  }

  .form-check-input {
    cursor: pointer;
    margin: 0;
    min-width: 25px;
    min-height: 25px;
    position: relative;
    box-shadow: none;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      border-radius: 50%;
      @include screen-lg-max {
        border: 1px solid rgba(144, 153, 161, 0.25);
      }
    }

    @include screen-xl-min {
      min-width: 20px;
      min-height: 20px;
    }

    &:checked {
      background-image: none;

      &:after {
        border: none;
        background: $primary;
      }

      &:before {
        content: '';
        position: absolute;
        left: -5px;
        top: -5px;
        border-radius: 50%;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 2px solid $primary;
      }
    }
  }

  .form-check-label {
    width: 100%;
    padding-left: 10px;
    font-size: 13px;

    @include screen-xl-min {
      font-size: 1rem;
      color: $darker-gray;
      height: inherit;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  &,
  .form-check {
    margin-bottom: 0;
    padding-left: 0;
  }

  &__check-icon {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: white;

    @include screen-xl-min {
      font-size: 16px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:126";