.layout {
  &__wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $light-azure;

    &--with-mobile-button {
      padding-bottom: 50px;
    }

    @include screen-lg-max {
      background: white;
    }
  }

  &__content-wrapper {
    align-items: flex-start;
    flex: 1;
    overflow: auto;
    width: 100%;
  }

  &__content {
    width: 100%;
    flex: 1;
  }
}

;@import "sass-embedded-legacy-load-done:101";