.reservation-payment-modal {
  &__image {
    padding-top: 54%;
    position: relative;
    background-position: center;
    background-size: cover;
  }

  &__steps {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.reservation-payment-source-form {
  &__side-image {
    width: 51px;
    max-height: 25px;
    align-self: center;
  }
}

.reservation-voucher-card {
  flex: 1 1 40%;

  @include screen-md-max {
    flex: 1 1 100%;
  }
}

.gastro-card {
  &__modal-body {
    width: 85%;
    padding-left: 30px;

    @include screen-lg-max {
      width: 100%;
      min-height: 200px;
    }
  }

  &__modal-wrapper {
    max-height: 303px;

    @include screen-md-max {
      max-height: unset;
    }
  }
}

.pending-reservation-mobile {
  &__check-in-button {
    height: 5rem;
  }
}

.reservation-payment-history {
  &__table {
    @include screen-md-max {
      width: calc(100% + 2rem) !important;
      min-height: 50px;
      margin-right: -1rem;
      margin-left: -1rem;
      margin-top: 1rem;
    }
  }

  &__cell {
    vertical-align: middle;
    width: 20%;

    &--first-column {
      width: 5%;
    }

    &--name {
      width: 15%;
    }

    &--quantity {
      width: 10%;
    }

    @include screen-sm-max {
      &--first-column {
        width: 55%;
      }
      &--quantity {
        width: 10%;
      }
      &--last-column {
        width: 35%;
      }
    }
  }

  &__no-data-indicator {
    position: absolute;
    left: 50%;
    top: calc(50% + 35px);
    transform: translate(-50%, -50%);
    border-bottom: 0;

    @include screen-md-max {
      width: 100%;
      top: 50%;
    }
  }
}

;@import "sass-embedded-legacy-load-done:135";