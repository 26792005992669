.reservation-row-banner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;

  &:before {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0.9;
    background-color: white;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
  }

  &:not(.is-collapsed) {
    @include fadeInAnimation;
  }

  &.is-collapsed {
    &:before {
      opacity: 0.95;
    }

    .reservation-row-banner__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      width: 90%;

      p {
        margin-bottom: 5px;
      }

      @include screen-sm-min {
        p {
          margin: 0 !important;
        }
      }

      @include screen-xs-max {
        gap: 0;
        &,
        .reservation-row-banner__content__main-text {
          flex-direction: column;
        }
      }

      .reservation-row-banner__text {
        text-align: start;

        @include screen-md-max {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }

  &__content {
    z-index: 1;
    text-align: center;
  }

  &__button {
    color: $white;
    font-weight: bold;
    padding: 10px 30px;

    @include screen-xs-max {
      width: 100%;
      padding: 8px;
    }
  }

  &__text {
    color: $primary;
    font-size: 18px;
    font-weight: bold;

    @include screen-lg-max {
      text-align: center;
    }
  }
}

;@import "sass-embedded-legacy-load-done:139";