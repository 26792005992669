.signature-pad {
  &__wrapper {
    position: relative;
  }

  &__full-screen {
    height: 100vh;
    height: -webkit-fill-available;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    user-select: none;
    overflow: scroll;
  }

  &__canvas {
    max-width: calc(100% - 20px);
    max-height: 60vh;
    width: 85vw;
    height: 300px;
    border: 1px solid gray;
    border-radius: 0.25rem;
    display: block;
    margin: 30px auto 50px auto;

    @include screen-xl-min {
      width: 100%;
      max-width: 100%;
      margin: 15px auto 30px auto;
    }
  }

  &__clear-button {
    transform: scale(-1, 1) translateX(2em);

    .icon-with-text {
      transform: scale(-1, 1);
    }
  }
}

;@import "sass-embedded-legacy-load-done:114";