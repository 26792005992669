.check-card {
  &__wrapper {
    cursor: pointer;
    background: $light-blue;
    border-width: 2px;
    color: $darker-gray;

    &.disabled {
      opacity: 0.6;
      cursor: default;
    }

    &.active {
      background: $primary;
      border-color: $primary;
      color: $white;

      .check-card {
        &__circle {
          background: $primary;
          border-color: $white;
        }
      }
    }

    .check-card {
      &__circle {
        width: 30px;
        height: 30px;
        background: $smoke-white;
        border-radius: 15px;
        border: solid 2px $lighter-gray;
      }

      &__content-wrapper {
        flex: 1;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:128";