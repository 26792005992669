.modal-form-step {
  &__wrapper {
    overflow: hidden;
    padding-left: 25px;
  }

  &__trapeze {
    background: $white;
    height: 60px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-top-left-radius: 24px;
    position: relative;

    &::before {
      background: $white;
      position: absolute;
      content: '';
      display: block;
      width: 22px;
      height: 55px;
      left: -13px;
      bottom: -10px;
      transform: rotate(30deg);
    }
  }

  &__progress {
    width: 40px;
  }

  &__step {
    top: 20px;
    left: 20px;
    transform: translate(-50%, -50%);
  }
}

;@import "sass-embedded-legacy-load-done:109";