.side-nav {
  &__wrapper {
    position: sticky;
    top: 1rem;

    @include screen-lg-max {
      display: none;
    }
  }

  &__nav-item {
    width: 280px;
    align-items: flex-start;
    color: $lighter-gray;
    border-bottom: 1px solid $base-gray;

    &.active {
      color: white;
      background-color: $dark-gray;

      .side-nav-icon {
        border-left-color: $black-olive;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .side-nav-icon {
      padding: 0.9rem 1.5rem;
      font-size: 20px;
      border-left: 0.3rem solid $darker-gray;
    }

    .side-nav-text {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__welcome {
    color: white;
  }

  &__user {
    color: white;
    font-weight: bold;
  }

  &__last-login {
    opacity: 0.6;
  }
}

;@import "sass-embedded-legacy-load-done:103";