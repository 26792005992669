.nfc-open-animation {
  g#phone {
    animation: phoneMoveIn 1000ms 1000ms both, fadeOut 800ms 2500ms forwards;
  }

  g#door-open {
    animation: fadeIn 600ms 3000ms both, fadeOut 600ms 4000ms forwards;
  }

  g#signal {
    animation: signalFadeOut 300ms 2400ms both, signalFadeOut 300ms 4000ms reverse forwards;
  }

  g#lock {
    animation: changeLockColor 300ms 2400ms both, changeLockColor 300ms 4000ms reverse forwards;
  }
}

.nfc-open-animation-end {
  g#phone {
    opacity: 0;
  }

  g#door-open {
    opacity: 0;
  }

  g#signal {
    opacity: 1;
  }

  g#lock {
    color: white;
  }
}

@keyframes phoneMoveIn {
  0% {
    transform: translate(100%, 100%) rotate(-30deg);
  }
  100% {
    transform: translate(11%, 18%) rotate(-30deg);
  }
}

@keyframes signalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes changeLockColor {
  0% {
    color: white;
  }
  100% {
    color: #0acf97;
  }
}

;@import "sass-embedded-legacy-load-done:138";