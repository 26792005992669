@mixin fadeInAnimation {
  animation: fadeIn 400ms linear forwards;
}

@mixin fadeOutAnimation {
  animation: fadeOut 400ms linear forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}

;@import "sass-embedded-legacy-load-done:93";