.tooltip {
  z-index: 4;
  position: relative;
  opacity: 1 !important;
  background-color: transparent !important;
  font-size: unset;

  .tooltip__content {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &--hovered:hover {
    .tooltip__content {
      visibility: visible;
      opacity: 1;
    }
  }

  &.no-pointer {
    .tooltip__content:after {
      display: none;
    }
  }

  &.is-right {
    .tooltip__content {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 5px);

      @include screen-sm-max {
        left: -25px;
      }
    }
  }

  &.is-reservation-number {
    .tooltip__content {
      left: -170px;
    }
  }

  &.is-payment-information {
    .tooltip__content {
      width: max-content;
      text-align: left;
    }
  }
}

.tooltip-inner {
  background-color: #3f3f3f !important;
}

.tooltip-arrow:before {
  border-top-color: #3f3f3f !important;
}

;@import "sass-embedded-legacy-load-done:118";