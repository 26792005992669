.receipt {
  &__header {
    &__reservation-number {
      font-size: 20px;
      white-space: nowrap;

      @include screen-lg-max {
        font-size: 16px;
        display: block;
        margin-top: -5px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:141";