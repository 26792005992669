.topbar {
  &__wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 5rem;
    justify-content: center;
    background-color: white;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    a {
      color: #212529;
      text-decoration: none;
    }
  }

  &__logo {
    @include screen-lg-max {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__menu-wrapper {
    display: flex;
    align-items: center;
    gap: 3rem;
    @include screen-lg-max {
      display: none;
    }
  }

  &__icon {
    color: $light-gray;
    @include screen-xl-min {
      display: none;
    }

    &--back {
      font-size: 2.4rem;
      margin: 0 -0.5rem;
    }

    &--close {
      font-size: 2.1rem;
      margin: 0 -0.35rem;
    }

    &--menu {
      font-size: 1.7rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:100";