.custom-alert {
  display: flex;
  padding: 13px 20px;
  border-radius: 4px;
  font-size: 0.9rem;

  @include screen-xs-max {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__info {
    border: 1px solid rgba(124, 139, 152, 0.5);
    background-color: rgba(236, 245, 252, 0.5);
  }

  &__info-icon {
    color: $primary;
    font-size: 15px;
    margin-right: 10px;
    line-height: 1;
  }
}

;@import "sass-embedded-legacy-load-done:120";