.coupons-view {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-image: url('/src/assets/images/coupons-bg.webp');
    background-size: cover;
    margin-left: -10px;
    margin-right: -10px;
    min-height: calc(100vh - 130px);
  }

  &__info-box {
    border-radius: 8px 8px 2px 2px;
    background-color: white;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
    margin-bottom: 15px;

    &:after {
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      content: '';
      border-top: 20px solid white;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 1px solid white;
      height: 0;
      display: inherit;
      border-radius: 3px;
    }
  }

  &__action-box {
    &__wrapper {
      border-radius: 2px 2px 8px 8px;
      margin-left: 10px;
      margin-right: 10px;
      background-color: white;
      margin-top: 20px;
      position: relative;
      padding: 4px;

      &:after {
        position: absolute;
        top: -19px;
        left: 0;
        width: 100%;
        content: '';
        border-bottom: 20px solid white;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        height: 0;
        display: inherit;
      }

      &:before {
        content: '';
        position: absolute;
        border-bottom: 1px dashed #a7a7a7;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 25px);
        top: -11px;
        z-index: 1;
      }
    }

    &__transaction-information {
      background-color: $light-blue;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      position: absolute;
      top: -27px;
      left: 50%;
      width: 80%;
      transform: translateX(-50%);
      font-size: $font-size-sm;
      padding: 5px;
      text-align: center;
    }

    &__content {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      background-color: #e7e7e7;
      position: relative;

      &:after {
        position: absolute;
        width: 100%;
        background-color: #e7e7e7;
        clip-path: polygon(10px 0, calc(100% - 10px) 0, 100% 52%, 100% 100%, 0 100%, 0 54%);
        content: '';
        height: 19px;
        left: 0;
        top: -10px;
        z-index: 2;
      }

      &.is-button {
        padding: 0;
        width: 100%;
        border: 0;
        background-color: $danger;
        display: flex;
        justify-content: center;

        button {
          background-color: $danger !important;
        }

        .coupons-view__payment-text {
          margin-bottom: 7px;
          z-index: 3;
        }

        &:after {
          background-color: $danger;
        }
      }

      &.is-gray {
        background-color: #8d8d8d;

        &:after {
          background-color: #8d8d8d;
        }
      }
    }
  }

  &__spin-input {
    height: 100%;

    .spin-input__container {
      height: 100%;
      background-color: $light-blue;
    }

    .spin-input {
      font-size: 30px;
      height: auto;
      background-color: $light-blue;
      line-height: 1;
      max-width: 100px;
    }

    .spin-input__minus,
    .spin-input__plus {
      font-size: 20px;
    }
  }

  &__list {
    margin-left: 10px;
    margin-right: 10px;

    &__item {
      background-color: $light-azure;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      display: flex;
      align-items: center;
      width: calc(70% - 10px);
      position: relative;

      &:after {
        background-color: $light-azure;
        clip-path: polygon(1px 0, 100% 8px, 100% 90%, 1px 100%, 0 100%, 0 0);
        content: '';
        height: 100%;
        position: absolute;
        right: -7px;
        top: 0;
        width: 8px;
        z-index: 2;
        border-left: 1px solid $light-azure;
      }

      &.is-used {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__item-button {
      background-color: $light-azure;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 11px;
      transition: opacity 200ms linear;
      position: relative;
      margin-left: 14px;
      width: 30%;
      padding-left: 4px;

      &.is-used {
        opacity: 0.5;
        pointer-events: none;
      }

      &:active,
      &:focus {
        opacity: 0.9;
      }

      &:after {
        background-color: $light-azure;
        clip-path: polygon(90% 0, 100% 0, 100% 100%, 90% 100%, 0 90%, 0 8px);
        content: '';
        height: 100%;
        left: -7px;
        position: absolute;
        top: 0;
        width: 8px;
        z-index: 2;
      }

      &:before {
        content: '';
        border-left: 1px dashed #a7a7a7;
        height: calc(100% - 18px);
        left: -7.5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
      }
    }
  }

  &__details {
    &__amount {
      font-size: 105px;
      text-align: center;
      display: block;
      line-height: 1;
      margin-top: 5px;
    }

    &__currency {
      font-size: 100px;
    }

    &__qr-code {
      width: 200px;
      height: 200px;
      border: 1px solid white;
      border-radius: 4px;
      background-color: gray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__code-box {
      display: flex;
      align-items: center;
      background-color: white;
      border: 4px dashed #d7d7d7;
      border-radius: 8px;
      padding: 10px 30px;
      max-width: 500px;
      justify-content: center;
      margin: 0 auto 20px;
      width: 100%;
    }
  }

  &__purchase-modal {
    &__submit {
      background: $danger !important;
    }
  }

  &__type-toggle {
    display: flex;
    justify-content: center;
    margin: 10px 25px 15px 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 4px;
    border: 1px solid #57606799;

    &__button {
      width: 50%;
      background-color: $white;
      color: $light-gray;
      border: 0;
      border-radius: 4px;
      padding-top: 12px;
      padding-bottom: 12px;
      margin: 0 3px;
      transition: background-color 200ms linear, color 200ms linear;
      font-size: $font-size-sm;

      &.is-active {
        background-color: $darker-gray;
        color: white;
      }
    }
  }

  &__tab-underline {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 2px;
    background: white;
    z-index: 1;
    border-radius: 4px;

    &__full {
      z-index: 0;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background: rgba(225, 225, 225, 0.5);
      border-radius: 4px;
    }
  }
}

.coupon-banner {
  &__close-button {
    box-shadow: none;
    border: none;
    position: absolute;
    right: 0;
    bottom: 0;
    background: transparent;
    padding-bottom: 5px;

    &:before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      background-color: #917046;
      height: 130px;
      width: 130px;
      rotate: 45deg;
    }
  }
}

;@import "sass-embedded-legacy-load-done:149";