html,
body {
  font-size: 13px;
  color: #576067;
  font-weight: 500;

  * {
    box-sizing: border-box;
    font-family: museo_sans, sans-serif !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.uil-user.user-circle {
  width: 1.8em;
  height: 1.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1em solid;
  border-radius: 50%;
}

.font-size-9 {
  font-size: 9.1px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-xxs {
  font-size: 0.7rem;
}

.font-size-xs {
  font-size: 0.8rem !important;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-md {
  font-size: 1rem !important;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem !important;
}

.font-size-xxl {
  font-size: 1.35rem;
}

.font-size-3xl {
  font-size: 3.4rem;
  line-height: 3.4rem;
}

.font-size-icon-sm {
  font-size: 2rem;
}

.font-size-icon-xl {
  font-size: 3rem;
}

.font-size-icon {
  font-size: 2.4rem;
}

.font-size-icon-18 {
  font-size: 1.35rem;
  line-height: 1.35rem;
}

.font-size-icon-22 {
  font-size: 1.7rem;
  line-height: 1.7rem;
}

.text-light-gray {
  color: $light-gray;
}

.text-lighter-gray {
  color: $lighter-gray;
}

.text-v-light-gray {
  color: $v-light-gray;
}

.text-darker-gray {
  color: $darker-gray;
}

.text-light-black {
  color: $light-black;
}

.text-navy {
  color: $navy-light;
}

.bg-light-azure {
  background-color: $light-azure;
}

.bg-light-blue {
  background: $light-blue;
}

.bg-secondary-light-blue {
  background: $secondary-light-blue;
}

.bg-darker-grey {
  background-color: $darker-gray;
}

.bg-v-lighter-gray {
  background: $v-light-gray;
}

.bg-dark-gray-gradient {
  background: $dark-gray-gradient;
}

.bg-gold-gradient {
  background: $gold-gradient;
}

.bg-dark-gray {
  background: $darker-gray !important;
}

.bg-tropical-blue {
  background-color: $tropical-blue;
}

.bg-alice-blue {
  background-color: $alice-blue;
}

.card > .section-divider {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.button-loading-spinner {
  height: 13px;
  width: 13px;
  border-width: 1px;
}

.mobile-break {
  @include screen-xl-min {
    display: none;
  }
}

.desktop-break {
  @include screen-lg-max {
    display: none;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

.fw-medium {
  font-weight: 500;
}

.transition-max-height {
  transition: max-height 250ms linear, background-color 250ms linear;
}

.transition-rotate-icon::before {
  transition: transform 250ms ease;
}

.lh-initial {
  line-height: initial;
}

.lh-sm {
  line-height: 0.85 !important;
}

.lh-inherit {
  line-height: inherit;
}

.overlap-border {
  &-top {
    &-1 {
      margin-top: -1px;
    }
  }

  &-right {
    &-1 {
      margin-right: -1px;
    }
  }

  &-bottom {
    &-1 {
      margin-bottom: -1px;
    }
  }

  &-left {
    &-1 {
      margin-left: -1px;
    }
  }
}

.round-border {
  &-tl {
    border-top-left-radius: 0.25rem;
  }

  &-tr {
    border-top-right-radius: 0.25rem;
  }

  &-bl {
    border-bottom-left-radius: 0.25rem;
  }

  &-br {
    border-bottom-right-radius: 0.25rem;
  }
}

.word-spacing-max {
  word-spacing: 100vw;
}

.object-fit-cover {
  object-fit: cover;
}

.br-0 {
  border-radius: 0 !important;
}

.underline-shadow {
  background-size: 1px 1em;
  box-shadow: inset 0 -0.175em white, inset 0 -9px rgba($gold-light, 0.4);
  display: inline;
}

img {
  user-select: none;
}

table {
  tr:nth-child(even) {
    background: $light-blue;
  }

  tr:last-child {
    border-bottom: $lighter-gray;
  }
}

.vertical-align-middle {
  vertical-align: middle;
}

.overflow-y-auto {
  overflow-y: auto;
}

.flex-1 {
  flex: 1;
}

.mh-95 {
  max-height: 95% !important;
}

.scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.container-xxl-full-width {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.z-index-1 {
  z-index: 1;
}

.letter-spacing-5 {
  letter-spacing: 5px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.border-lighter-gray {
  border-color: $lighter-gray !important;
}

.h-fit-content {
  height: fit-content;
}

.responsiveness-button {
  @include screen-xs-max {
    max-width: 500px;
    width: 100%;
  }
}

.btn-tall {
  min-height: 45px;
}

.btn-outline-secondary:hover {
  color: white !important;
}

.w-fit {
  width: fit-content;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #a5a5a5;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(#a5a5a5, 0.7);
}

;@import "sass-embedded-legacy-load-done:94";