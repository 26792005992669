.wallet {
  &__grey-box {
    border-radius: 4px;
    background-color: rgba($light-azure, 0.5);
    border: 1px solid rgba($darker-gray, 0.5);
  }

  &__method {
    &__logo-wrapper {
      border: 1px solid #ddd;
      width: calc(min(150px, 33%));
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 19px 13px;
      margin-left: -1px;
      margin-top: -1px;
      transition: 0.2s;

      &.active {
        background: #ddd;
      }

      img {
        height: 100%;
        max-width: 100%;
        object-fit: contain;
      }
    }
    &__expandable-privacy {
      margin: 20px auto 0 auto;
      max-height: 30px;
      font-size: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:133";