.subscription {
  &__code {
    .icon-with-text__text {
      min-width: 120px;

      @include screen-xs-max {
        min-width: auto;
      }
    }

    @include screen-sm-max {
      font-size: 0.9rem;

      .icon-with-text__icon {
        font-size: 20px;
      }
    }

    &.is-short {
      .icon-with-text__text {
        min-width: 90px;

        @include screen-xs-max {
          min-width: auto;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:145";