$accordion-border-color: #dee2e6;
$accordion-background: #f7fbfe;
$improvement-card-background: #f6fafe;

.improvements {
  &__summary {
    &__item {
      border-left: 1px solid $accordion-border-color;
      border-right: 1px solid $accordion-border-color;
    }

    &__item + &__item {
      border-top: 1px solid $accordion-border-color;
    }

    &__remove-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 10px;
      margin-left: 10px;
    }

    &__final-price {
      padding-right: 40px;

      @include screen-sm-max {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
      }
    }

    .accordion-header {
      border: 1px solid $accordion-border-color;
    }

    .accordion-item {
      background-color: $accordion-background;
      border: none;
    }

    .accordion-button {
      background-color: $accordion-background;
      color: $darker-gray;

      &:not(.collapsed) {
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
        border-color: transparent;
      }

      &:not(.collapsed):after {
        transform: rotate(0deg);
        box-shadow: none;
      }

      &:after {
        transform: rotate(-90deg);
        background-size: 1rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &__card {
    border: none;
    background-color: $improvement-card-background;

    &__content {
      border: 1px solid #80888d;
      border-radius: 0.25rem;
    }

    &:not(.is-md-full-width) {
      .improvements__card__content {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }
    }

    &.is-md-full-width {
      .improvements__card__content {
        border-radius: 0 0 0.25rem 0.25rem;
        border-top: none;
      }

      @include screen-md-min {
        display: grid !important;
        grid-template-columns: 40% 60%;

        .improvements__card__content {
          border-radius: 0 0.25rem 0.25rem 0;
          border-left: none;
          border-top: 1px solid #80888d;
        }
      }
    }

    &.is-stay-option {
      .improvements__card__title {
        min-height: 40px;
      }
    }

    &__image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 0.25rem 0 0 0.25rem;
      border: 1px solid #80888d14;
      border-bottom: 0;
      min-height: 205px;

      &.is-small {
        min-height: 180px;
        max-height: 180px;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
        border: 1px solid #80888d14;
        border-bottom: 0;
      }

      @include screen-md-min {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border: 1px solid #80888d14;
        border-right: 0;
        min-height: auto;

        &.is-small {
          max-height: 150px;
          min-height: 150px;
        }
      }
    }

    &__description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      min-height: 40px;
      margin-bottom: 0;
    }
  }

  &__modal {
    .modal-content {
      max-width: 600px;
      margin: 0 auto;
    }

    &__body {
      padding: 0;
    }

    &__amount-change {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include screen-xs-max {
        width: 100%;
        flex-direction: row;
        align-items: center;
      }
    }

    &__steps {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.improvement-vip-modal {
  &__list {
    column-count: 2;
    display: flex;
    flex-direction: column;

    @include screen-xl-min {
      display: block;
    }
  }
}

;@import "sass-embedded-legacy-load-done:112";