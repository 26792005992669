.form-control-with-status {
  text-overflow: ellipsis;
  transition: padding-right 100ms linear, background-color 200ms linear;

  &:disabled {
    transition: background-color 100ms linear;
    background-color: #f2f2f2 !important;
    color: #999999;
  }

  &.no-value {
    background: $light-blue;
    color: $light-gray;
  }

  &__container {
    position: relative;
    overflow: hidden;
  }

  &__spinner,
  &__succeed,
  &__failed {
    position: absolute;
    margin: auto;
    right: -25px;
    transition: right 100ms linear;
  }

  &__spinner {
    width: 1.2rem;
    height: 1.2rem;
    border: 0.2em solid;
    border-right: 0.2em solid transparent;
    top: 17px;
  }

  &__succeed,
  &__failed {
    top: 11px;
  }

  &--active,
  &--succeed,
  &--failed {
    .form-control-with-status {
      padding-right: 40px;
    }
  }

  &--active {
    .form-control-with-status__spinner {
      right: 10px;
    }
  }

  &--succeed {
    .form-control-with-status__succeed {
      right: 10px;
    }
  }

  &--failed {
    .form-control-with-status__failed {
      right: 10px;
    }

    .form-control-with-status {
      background: $danger-transparent;
      border-color: $danger;
    }
  }
}

.form-control {
  border-color: $lighter-gray;

  &__suffix {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.form-control-height-lg {
  height: 48px;
}

.form-control-textarea {
  padding-top: 12px;
  resize: none;

  &--failed {
    background: $danger-transparent !important;
    border: 1px solid $danger !important;
  }
}

;@import "sass-embedded-legacy-load-done:129";