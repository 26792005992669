.checked-in-reservation {
  &__footer-option {
    @include screen-sm-max {
      background: $light-blue;
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }
}

.checked-in-reservation-map-modal {
  &__zoom-buttons {
    &__wrapper {
      position: absolute;
      bottom: 25px;
      right: 25px;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
    }

    &__spacer {
      width: 70%;
      margin: 0 auto;
    }
  }

  &.preview-unavailable {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

;@import "sass-embedded-legacy-load-done:136";