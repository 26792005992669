.client-profile-guest-box {
  &__wrapper {
    position: relative;
  }

  &__delete-button {
    &--icon {
      font-size: 1.2rem;
    }
  }

  &__swipe-background {
    position: absolute;
    background-color: $danger;
    border-radius: 0.45rem;
    border: 1px solid $danger;
    width: calc(70px + 0.25rem);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
  }
}

;@import "sass-embedded-legacy-load-done:132";