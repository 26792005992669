.status {
  &--confirmed,
  &--active,
  &--active_assign {
    background-color: #e9f3eb;
    border: 1px solid #cae2d2;
    color: #2c8f41;
  }

  &--initial,
  &--generate {
    background-color: #e7e9ed;
    border: 1px solid #0f2453;
    color: #0f2453;
  }

  &--completed,
  &--used {
    background-color: #f3f4f5;
    border: 1px solid #b6bdc3;
    color: #9099a1;
  }

  &--cancelling {
    background-color: #fff5d4;
    border: 1px solid rgba(241, 186, 0, 1);
    color: #9f7b00;
  }

  &--initial_resume {
    background-color: #e7e9ed;
    border: 1px solid #0f2453;
    color: #0f2453;
  }

  &--cancelled,
  &--removed,
  &--initial_canceled {
    background-color: #ffeaea;
    border: 1px solid $danger;
    color: $danger;
  }
}

;@import "sass-embedded-legacy-load-done:143";