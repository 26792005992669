.scrollable-section {
  overflow-x: hidden;

  &__title {
    color: $dark-gray;

    @include screen-xl-min {
      color: $primary;
    }
  }

  &__card {
    &__image {
      object-fit: cover;
      height: 100%;
      width: 100%;
      max-height: 300px;
      min-height: 250px;
    }
  }
}

.scrollable-section-tabs {
  transition: transform 200ms linear;
  display: flex !important;
  justify-content: space-between;

  &__single {
    color: $darker-gray;
    cursor: pointer;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  &__wrapper {
    padding-bottom: 3px;
    border-bottom: 2px solid #d1d1d1;
    padding-left: 0;
    padding-right: 0;
    display: none;

    @include screen-lg-max {
      display: block;
    }
  }

  .is-active {
    font-weight: bold;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -1rem;
      bottom: -5px;
      height: 2px;
      width: calc(100% + 25px);
      background-color: $primary;
      user-select: none;
    }
  }

  .is-crossed {
    pointer-events: none;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    text-decoration-color: $primary;
  }
}

;@import "sass-embedded-legacy-load-done:111";