.footer {
  &__wrapper {
    display: flex;
    align-items: center;
    height: 5rem;
    background-color: $darker-gray;
    gap: 1rem;

    & > .footer__nav-item {
      flex: 1;
      color: $v-light-gray;
      flex-direction: column;
      height: 100%;

      &.active {
        color: white;
        background-color: $dark-gray;
      }

      & .nav-icon {
        font-size: 2rem;
        margin-bottom: -0.5rem;
      }

      & .nav-text {
        font-weight: bold;
        font-size: 0.6rem;
        white-space: nowrap;
      }
    }
  }
}

@include screen-xl-min {
  .footer {
    &__wrapper {
      display: none;
    }
  }
}

;@import "sass-embedded-legacy-load-done:102";