.modal-content {
  @include screen-sm-max {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
  }

  .full-modal-width {
    width: calc(100% + 2rem);
    margin: 0 -1rem;
  }
}

.modal-header {
  &__title {
    @include screen-md-min {
      border-right: 1px solid #dee2e6;
    }
  }

  .btn-close {
    padding: 18px;
    margin: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.modal-mobile-footer {
  button {
    border: none;
    border-radius: 0;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      filter: brightness(1.2);
    }

    &:active {
      filter: brightness(1);
    }
  }

  &__cancel-button {
    background: $light-gray;
  }

  &__submit-button {
    background: $dark-gray-gradient;
  }
}

.modal-dialog {
  @include screen-sm-max {
    max-width: 100%;
  }
}

.modal-body {
  &.modal-scrollable {
    max-height: calc(100vh - 14rem);
    overflow: auto;
  }

  .px-lg-4.px-0 {
    .body-width {
      margin-right: -1rem;
      margin-left: -1rem;

      @include screen-lg-min {
        margin-right: -2.4rem;
        margin-left: -2.4rem;
      }
    }

    .body-padding {
      padding-right: 2.4rem;
      padding-left: 2.4rem;
    }
  }
}

.non-closing-modal {
  .modal-header {
    .btn-close {
      display: none;
    }
  }
}

.borderless-header {
  .modal-header {
    &,
    &__title {
      border: none;
    }

    .btn-close {
      border-left: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.no-header {
  .modal-header {
    display: none;
  }
}

.is-red {
  .modal-content {
    background-color: #dd3535;
  }
}

.btn-light-gray {
  background: $lighter-gray;

  &:focus {
    background: $lighter-gray;
  }

  &:hover {
    background: $lighter-gray;
  }
}

.modal-backdrop {
  display: none;
}

.modal {
  background-color: $darker-gray;

  @include screen-xl-min {
    background: rgba(0, 0, 0, 0.3);
  }
}

.modal :not([data-modal-active='active']) .modal-content {
  opacity: 0;
}

.modal-semi-lg {
  .modal-dialog {
    @include screen-md-min {
      max-width: 600px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:105";