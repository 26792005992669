.check-in-modal {
  &__parking {
    .plates-form-array-input__form-group {
      flex: 1 1 auto;
      width: fit-content !important;

      @include screen-lg-min {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex: inherit;

        .form-control {
          width: 283px;
        }

        label {
          width: 245px;
        }
      }
    }

    &__add-button {
      text-align: center;
      border: 1px solid $lighter-gray;
      border-radius: 4px;
      padding: 8px 0;
      margin: 8px 0 !important;
      background: $dark-gray-gradient;

      .icon-with-text {
        color: white;
      }

      @include screen-lg-min {
        margin: 5px 0 !important;
        background: transparent;
        padding: 0;
        text-align: start;
        border: none;

        .icon-with-text {
          color: $text-muted;
        }
      }
    }
  }

  &__time {
    background-color: #eff3f7;
    border: 1px solid #495057;
    padding: 10px;
    width: 60px;
    height: 60px;
    border-radius: 4px;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #6c757d;

    &:focus-visible {
      outline: none;
    }
  }
}

.time-selection {
  &__wrapper {
    position: relative;
    height: 300px;
    width: 100px;
    overflow: hidden;
  }

  &__content-shadow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: $light-blue;

    &--pre {
      pointer-events: none;
      content: '';
      position: absolute;
      margin-top: 0;
      z-index: 1;
      width: 100px;
      height: 100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.05) 100%);
    }

    &--after {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 200px;
      width: 100px;
      height: 100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 1) 70%);
    }
  }

  &__item-wrapper {
    width: 107px;
    height: 100%;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    position: relative;
    scrollbar-width: none;
    scrollbar-color: transparent transparent;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
      width: 0;
      height: 0;
    }
  }

  &__item {
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    font-size: 2rem;
  }
}

;@import "sass-embedded-legacy-load-done:140";