.time-remaining-counter {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-xl-min {
      flex-direction: row;
      gap: 0.5rem;
    }

    &--lg {
      .time-remaining-counter__progress {
        min-width: 90px;
        max-width: 90px;
      }

      .time-remaining-counter__count {
        &-value {
          font-size: 33px;
          margin-bottom: -12px;
          margin-top: -6px;

          &--sm {
            font-size: 26px;
          }
        }
      }

      .time-remaining-counter__label {
        display: none;
      }
    }

    &--sm {
      .time-remaining-counter__progress {
        min-width: 40px;
        max-width: 40px;
      }

      .time-remaining-counter__count {
        &-value {
          font-size: 14px;

          &--sm {
            font-size: 13px;
          }
        }

        &-label {
          display: none;
        }
      }
    }
  }

  &__count {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $darker-gray;

    &-value {
      transition: font-size 0.3s ease;
    }
  }

  &__label {
    color: $darker-gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;

    &--sm {
      font-size: 10px;
    }

    &-years {
      margin-bottom: -2px;
    }
  }

  &__progress {
    transition: min-width 0.3s ease;
  }
}

;@import "sass-embedded-legacy-load-done:106";