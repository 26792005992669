.expandable-row-card {
  &__loader {
    min-height: 170px;
    width: calc(100% + 45px);

    .content-loader__content {
      width: calc(100% - 45px);
    }

    &.is-content-blue {
      .content-loader:after {
        background-color: red;
      }
    }
  }

  &__expand-icon {
    z-index: 1;

    @include screen-lg-max {
      z-index: auto;
    }
  }
}

;@import "sass-embedded-legacy-load-done:117";