.subscription-contract {
  &__banner {
    max-width: 280px;
    border-color: rgba($success, 0.25);
    background: linear-gradient(100deg, rgba(133, 200, 115, 0.25) 0, rgba(64, 149, 45, 0.25) 100%);
    padding-top: 45px;
    padding-bottom: 20px;
    overflow: hidden;
    display: none;

    @include screen-xl-min {
      display: block;
    }

    &__title {
      position: absolute;
      left: 0;
      top: 0;
      background: $success;
      color: white;
      font-size: 1.5rem;
      padding: 4px 30px 4px 20px;
      border-bottom-right-radius: 30px;

      &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border: 30px solid transparent;
        border-bottom-color: $success;
        position: absolute;
        top: 0;
        right: -19px;
        transition: border-bottom-color 0.3s ease;
        transform: rotate(180deg);
      }
    }

    &__percent-watermark {
      bottom: -20px;
      height: 140px;
      position: absolute;
      right: -30px;
      width: 150px;
      z-index: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:121";