.icon-connector {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 30px;
    background-color: white;
    top: -14px;
    left: 7px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 30px;
    background-color: $border-color;
    top: -14px;
    left: 17px;
  }
}

;@import "sass-embedded-legacy-load-done:137";