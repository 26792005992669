.promocode-table {
  &__wrapper {
    max-height: 180px;
    overflow-y: scroll;
  }

  &__container {
    max-width: 410px;
  }

  &__year-row {
    td {
      padding-top: 10px;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e0e0e0;
    border-radius: 20%;
  }

  ::-webkit-scrollbar-thumb {
    background: #a5a5a5;
    border-radius: 20%;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(#a5a5a5, 0.7);
  }
}

;@import "sass-embedded-legacy-load-done:144";