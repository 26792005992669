.spinner {
  &--small {
    height: 0.85rem;
    width: 0.85rem;
    font-size: 0.4rem;
  }

  &--md {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 0.8rem;
  }
}

;@import "sass-embedded-legacy-load-done:99";